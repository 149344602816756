$(document).ready(function(){

    $(".meta-buttons__listitem a").click(function(){

        var target = $(this).data("target");

        //$(".meta-item").not(target).slideUp(250);

        if ($(target).is(":hidden")){
            $(target).slideDown(250);
            $('.meta-nav').addClass("open").trigger("shrinkHeight");

            if (target == ".meta-search") {
                $(".meta-search").find(".form-control").focus();
            }

        } else {

            $(target).slideUp(250);
            $('.meta-nav').removeClass("open");
        }
    })

    $(".toolbar__option--showmenu").click(function () {


        //needs jquery UI
        //$("#menu-overlay").toggle('slide', {direction: 'left'}, 250);
        $("#menu-overlay").animate({width: 'toggle'}, 200);

    })


    $('.slider-main').slick({
        dots: true,
        arrows: false,
        swipe: false,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        customPaging: function(slider, i) {
            var title = $(slider.$slides[i]).data('title');
            return '<a class="slider-main__nav-btn" href="javascript:void(0);">'+title+'</a>';
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    customPaging : function(slider, i) {
                        return '<a class="slider-main__nav-btn" href="javascript:void(0);">'+i+'</a>';
                    }
                }
            }
        ]
    });

    $('.slider').slick({
        dots: false,
        arrows: true,
        autoplay: false,
        slidesToShow: 5,
        infinite: true,
        slidesToScroll: 1,
        swipe: false,
        // customPaging: function(slider, i) {
        //     var title = $(slider.$slides[i]).data('title');
        //     return '<a class="slider-main__nav-btn" href="javascript:void(0);">'+title+'</a>';
        // },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: "unslick"
            }
        ]
    });

    $(".icon-unfold").click(function(){

        $(this).toggleClass("icon-unfold--down");

        $(this).parents().closest('li').find('dropdown').addClass("open");

        var dropdown = $(this).next();

        dropdown.slideToggle();

    });

    // var windowWidth = $(window).width();
    // if (windowWidth >= 767) {
    //
    //
    //
    // }

    $(".fancybox").fancybox();

    // $('#body').on('openMenu', '#menu-opener', function() {
    //     var $menu = $('#menu-overlay');
    //     if( $menu.hasClass('busy')) {
    //         return false;
    //     }
    //
    //     $(this).hide();
    //     $menu.addClass('busy');
    //     $menu.toggle('slide', {direction: 'left'}, 250, function() {
    //         $(this).removeClass('busy');
    //     });
    // }).on('closeMenu', '#menu-overlay', function() {
    //     var $menu = $('#menu-overlay');
    //     if( $menu.hasClass('busy')) {
    //         return false;
    //     }
    //
    //     $menu.addClass('busy');
    //     $menu.toggle('slide', {direction: 'left'}, 250, function() {
    //         $(this).removeClass('busy');
    //         $('#menu-opener').fadeIn(400);
    //     });
    // });
    //
    // // call hover & mouseleave events (won´t work with on() .. )
    // $('#menu-opener').hover(function(){
    //     $('#menu-opener').trigger('openMenu');
    // });
    //
    // $('#menu-overlay').mouseleave(function(){
    //     $('#menu-overlay').trigger('closeMenu');
    // });
    //
    // $('.show-menu').click(function() {
    //     if($('#menu-overlay:visible').length > 0) {
    //         $('#menu-overlay').trigger('closeMenu');
    //     } else {
    //         $('#menu-opener').trigger('openMenu');
    //     }
    // });

});